let EfbHelperDialog = function (options) {

    let instance = this;

    let _private = {
        initialized:    false,
        internalEvents: ['open', 'close', 'create']
    };


    _private.options = {
        clear:           true,
        resizable:       false,
        modal:           false,
        titleBar:        false,
        closeOutside:    false,
        isConfirmDialog: false,
        position:        {my: "center", at: "center", of: window},
        id:              'efb-helper-dialog',
        zIndex:          1000000000,
        minHeight:       500,
        width:           960
    };

    _private.contentWrap = undefined;


    this.init = function () {
        options              = options || {};
        _private.initialized = true;
        _private.prepareSize(options);
        _private.options     = $.extend(_private.options, options);
        instance.dialogDestroy();
        _private.internalEvents.forEach(function (event) {
            _private['event_' + event] = _.isFunction(_private.options[event]) ? _private.options[event] : function () {};
            _private.options[event]    = _private['dialog' + $.ucfirst(event)];
        });
        _private.prepareButtons();
        _private.prepareDialogClass();
        _private.prepareConfirmDialog(options);
    };

    this.show = function () {
        if (!_private.initialized) {
            instance.init();
        }
        _private.contentWrap = undefined;
        let id = _private.options.id;
        if (_private.options.clear) {
            let dialogWrap = $('.efb-dialog.ui-dialog');
            dialogWrap.find('#' + id).html('');
            dialogWrap.find('.ui-dialog-buttonpane').remove();
        }
        _private.dialogContainer = $('#' + id);
        if (!_.isUndefined(_private.options.container)) {
            _private.dialogContainer = _private.options.container;
        }
        if (_private.dialogContainer.length === 0) {
            _private.dialogContainer = jQuery('<div/>', {id: id});
            _private.dialogContainer.appendTo('body');
        }
        _private.setContent();
        _private.dialogContainer.hide();
        _private.dialogContainer.dialog(_private.options).dialog("open");
        if (!_.isUndefined(_private.options.zIndex)) {
            $('div.ui-dialog.efb-dialog').css('z-index', _private.options.zIndex);
        }
        return _private.dialogContainer;
    };

    this.close = () => {
        _private.dialogClose()
    };

    _private.dialogCreate = function (event, ui) {
        $(event.target).parent().find('.ui-dialog-titlebar-close').text('');
        if (_private.options.closeOutside) {
            $('body').bind('mousedown.dialogclose', function (event) {
                if ($(event.target).parents('div.ui-dialog').length) {
                    return;
                }
                try {
                    _private.dialogClose(event);
                } catch (exception) {
                    console.log(exception);
                }
            });
        }
        _private['event_create'](event, ui);
    };

    _private.dialogOpen = function (event, ui) {
        if (_private.options.modal) {
            $('body').addClass('modal');
            $('.ui-widget-overlay').css('zIndex', _private.options.zIndex - 1);
        }
        if (!_private.options.titleBar && _private.options.closeOutside) {
            _private.dialogContainer.prev().hide();
        }
        _private['event_open'](event, ui);
    };

    _private.dialogClose = function (event, ui) {
        let body = $('body');
        body.unbind('mousedown.dialogclose');
        if (_private.options.modal) {
            body.removeClass('modal');
        }
        if (typeof _private.options.onClose === 'function') {
            _private.options.onClose();
        }
        if(_private.contentWrap){
            _private.contentWrap.append(_private.options.html);
        }
        _private.dialogContainer.dialog('close');
        _private['event_close'](event, ui);
    };

    _private.prepareButtons = function () {
        if (_.isUndefined(_private.options.buttons)) {
            return;
        }
        $.each(_private.options.buttons, function (buttonKey, button) {
            if (_.isObject(button) && !_.isUndefined(button.text)) {
                button.text = EfbHelper._(button.text);
                return;
            }
            if (EfbHelper._(buttonKey) === buttonKey) {
                return;
            }
            _private.options.buttons[EfbHelper._(buttonKey)] = button;
            delete _private.options.buttons[buttonKey];
        });
    };

    _private.prepareDialogClass = function () {
        if (_.isUndefined(_private.options.dialogClass)) {
            _private.options.dialogClass = 'efb-dialog';
        } else {
            _private.options.dialogClass += ' efb-dialog';
        }
        if (_private.options.modal === true) {
            _private.options.dialogClass += ' modal';
        }
    };

    _private.prepareConfirmDialog = function (options = {}) {
        if (!_private.options.isConfirmDialog) {
            return;
        }
        _private.options.modal        = true;
        _private.options.width        = undefined;
        _private.options.height       = undefined;
        _private.options.minWidth     = 400;
        _private.options.minHeight    = 120;
        _private.options.closeOutside = true;
        _private.options.dialogClass += ' confirm-dialog';
        Object.keys(options).forEach(key => {
            if(['dialogClass'].indexOf(key) !== -1){
                return;
            }
            if(_private.options.hasOwnProperty(key) && _private.internalEvents.indexOf(key) === -1){
                _private.options[key] = options[key];
            }
        })
    };

    _private.setContent = function () {
        _private.dialogContainer.empty();
        if (!_.isUndefined(_private.options.text)) {
            _private.options.text = EfbHelper._(_private.options.text);
            _private.dialogContainer.text(_private.options.text);
        }
        if (!_.isUndefined(_private.options.html)) {
            if(_.isObject(_private.options.html) && _private.options.html.parent){
                _private.contentWrap = _private.options.html.parent();
            }
            _private.dialogContainer.html(_private.options.html);
        }
        if (!_.isUndefined(_private.options.ajax)) {
            let success = function (response, dialogContainer) {
                dialogContainer.html(response)
            };
            if (_.isFunction(_private.options.ajax.success)) {
                success = _private.options.ajax.success;
                delete _private.options.ajax.success;
            }
            let ajaxOptions = {
                type:       'get',
                beforeSend: function () {
                    _private.dialogContainer.addClass('spinner');
                },
                success:    function (response) {
                    success(response, _private.dialogContainer);
                    _private.dialogContainer.removeClass('spinner');
                    _private.dialogContainer.parent().position(_private.getBestPosition());
                }
            };
            ajaxOptions     = $.extend(ajaxOptions, _private.options.ajax);
            $.ajax(ajaxOptions);
        }
    };

    _private.getBestPosition = function () {
        let position     = _private.options.position;
        let windowHeight = $(window).height();
        let dialogHeight = _private.dialogContainer.parent().outerHeight();
        if (dialogHeight > windowHeight) {
            position = {my: "center top", at: "center top", of: window};
        }
        return position;
    };

    _private.prepareSize = function () {
        if(!options.size){
            return;
        }
        ['width','height','minWidth','minHeight'].forEach(function (t) {
            if(_.isUndefined(options[t])){
                options[t] = options.size;
            }
        });
    };


    this.dialogDestroy = function (options) {
        options = options || _private.options;
        try {
            $('body').removeClass('modal');
            $('#' + options.id).dialog('destroy');
        } catch (exception) {
        }
    };

    this.updatePosition = function (options) {
        options       = options || _private.options;
        let container = $('#' + options.id);
        let position  = container.dialog('option', 'position');
        container.dialog('option', 'position', position);
        return this;
    };

    this.find = function (selector) {
        return _private.dialogContainer.find(selector);
    }
};